import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/manage/bidGuide/list`,
    method: 'POST',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/manage/bidGuide/del?id=${id}`,
    method: 'post',
    data: {}
  })
}
export function addAPI(data) {
  return http({
    url: `/api/manage/bidGuide/add`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/manage/bidGuide/edit`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/manage/bidGuide/detail`,
    method: 'post',
    params: { id }
  })
}

export function getTreeAPI(params) {
  return http({
    url: `/api/manage/bidGuide/tree`,
    method: 'POST',
    params
  })
}